/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState, useEffect, forwardRef } from "react";
import { PiUsers } from "react-icons/pi";
import { IoCloseOutline } from "react-icons/io5";
import InputText from "../input/InputText";
import toast from "react-hot-toast";
import useAggregator from "../../hooks/useAggregator";
import SearchableDropdown2 from "../input/SearchableDropdown2";

import { getState, getLgaByState } from "../../ds/resource";
import { Formik } from "formik";
import { Form } from "react-router-dom";
import { createAggregatorSchema } from "../../utils/validationSchema/aggregatorSchema";
import { useSelector } from "react-redux";
import ReactDatePicker from "react-datepicker";

import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DeleteModal from "../../components/modal/DeleteModal";
export const AggregatorModal = ({
  states,
  lgas,
  closeModal,
  requestType,
  getAggregators,
  useerData,
  setSelectedStateId,
}) => {
  const modalRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [formatStartDate, setFormatStartDate] = useState(null);
  const [formatEndDate, setFormatEndDate] = useState(null);
  const { createNewAggregator, updateExistingAggregator } = useAggregator();
  const { program } = useSelector((state) => state.auth);

  const initialValues = {
    name: requestType === "edit" ? useerData?.name : "",
    address: requestType === "edit" ? useerData?.address : "",
    contactPersonFirstName:
      requestType === "edit" ? useerData?.contactPersonFirstName : "",
    contactPersonLastName:
      requestType === "edit" ? useerData?.contactPersonLastName : "",
    phoneNumber: requestType === "edit" ? useerData?.phoneNumber : "",
    email: requestType === "edit" ? useerData?.email : "",
    location: requestType === "edit" ? useerData?.location : "",
    state: requestType === "edit" ? useerData?.state : "",
    yearOfIncorporation:
      requestType === "edit" ? useerData?.yearOfIncorporation : "",
    rcNumber: requestType === "edit" ? useerData?.rcNumber : "",
    longitude: requestType === "edit" ? useerData?.longitude : 0,
    latitude: requestType === "edit" ? useerData?.latitude : 0,
    noOfFemaleEmployee:
      requestType === "edit" ? useerData?.noOfFemaleEmployee : 0,
    noOfMaleEmployee: requestType === "edit" ? useerData?.noOfMaleEmployee : 0,
    avgMonthlyCollectionCap:
      requestType === "edit" ? useerData?.avgMonthlyCollectionCap : 0,
    avgMonthlyProcessionCap:
      requestType === "edit" ? useerData?.avgMonthlyProcessionCap : 0,
    isCurrentIncomeHigher:
      requestType === "edit" ? useerData?.isCurrentIncomeHigher : "",
    receiveTechnicalAssistance:
      requestType === "edit" ? useerData?.receiveTechnicalAssistance : "",
    gender: requestType === "edit" ? useerData?.gender : "",
    ageRange: requestType === "edit" ? useerData?.ageRange : "",
    cooperative: requestType === "edit" ? useerData?.cooperative : "",
    enrollmentDate: requestType === "edit" ? useerData?.enrollmentDate : "",
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="flex flex-col gap-2">
      <label className={`after:content-['*'] after:text-red-500`}>
        Enrollment Date
      </label>
      <div
        className="example-custom-input border border-gray-300 h-[44px] w-[140px] md:w-[200px] lg:w-[280px] p-2  rounded-md cursor:pointer"
        onClick={onClick}
        ref={ref}
      >
        <h3>{value ? value : "Select date"}</h3>
      </div>
    </div>
  ));
  const ExampleCustomInputTwo = forwardRef(({ value, onClick }, ref) => (
    <div className="flex flex-col gap-2">
      <div
        className="example-custom-input border border-gray-300 h-[44px] w-[140px] md:w-[200px] lg:w-[280px] p-2  rounded-md cursor:pointer"
        onClick={onClick}
        ref={ref}
      >
        <h3>{value ? value : "Select date"}</h3>
      </div>
    </div>
  ));

  const onSubmit = async (data) => {
    try {
      if (requestType === "edit") {
        setLoading(true);
        const res = await updateExistingAggregator({
          ...data,
          id: useerData.id,
          programId: program,
        });
        if (res.code === "00") {
          toast.success("Aggregator updated");
          closeModal();
        }
        if (res.code !== "00") {
          setLoading(false);
          toast.error(res.message || "Error updating waybill");
        }
      }
      if (requestType !== "edit") {
        setLoading(true);
        const res = await createNewAggregator({
          ...data,
          programId: program,
        });

        if (res.code === "00") {
          toast.success("Aggregator created");
          closeModal();
        }
        if (res.code !== "00") {
          setLoading(false);
          toast.error(res.message || "Error creating Aggregator");
        }
      }
    } catch (error) {
      toast.error(error.message || "something went wrong");
    } finally {
      setLoading(false);
      setTimeout(() => {
        getAggregators();
      }, 1000);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createAggregatorSchema}
      onSubmit={onSubmit}
    >
      {({ handleChange, errors, values, handleSubmit, setFieldValue }) => {
        return (
          <Form style={{ height: "500px" }}>
            <div className="flex flex-col gap-y-2 min-w-[300px] md:min-w-[450px] lg:w-[640px]  bg-white p-4 ">
              <div className="flex justify-between">
                <PiUsers style={{ width: 26, height: 26 }} />
                <IoCloseOutline
                  style={{ width: 26, height: 26, cursor: "pointer" }}
                  onClick={() => closeModal()}
                />
              </div>
              <div className="">
                <h1 className="capitalize font-bold">
                  {requestType === "edit"
                    ? "Edit Aggregator"
                    : "create new Aggregator"}
                </h1>
                <p className="text-sm">Enter the details below</p>
              </div>
              <div className="flex justify-between gap-2">
                <InputText
                  label={"Business Name"}
                  name="name"
                  error={errors.name}
                  value={values.name}
                  fieldRequired={true}
                  // className={"sm:w-[150px]"}
                  placeholder={"Enter business name"}
                  handleChange={handleChange}
                />

                <InputText
                  label={"Business Address"}
                  name="address"
                  value={values.address}
                  fieldRequired={true}
                  // className={"sm:w-[150px]"}
                  error={errors.address}
                  placeholder={"Enter business address"}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex justify-between w-full gap-2">
                <SearchableDropdown2
                  label={"State"}
                  options={states || []}
                  name="state"
                  error={errors.state}
                  fieldRequired={true}
                  // dropDownStyle={"sm:w-[150px]"}
                  // dropDownStyle={}
                  placeholder="Select state"
                  defaultInputValue={values.state}
                  handleChange={(selectionOption) => {
                    setFieldValue("state", selectionOption.label);
                    setSelectedStateId(selectionOption.value);
                  }}
                />

                <SearchableDropdown2
                  label={"Lga"}
                  options={lgas || []}
                  name="location"
                  error={errors.location}
                  fieldRequired={true}
                  // className={"w-[200px]"}
                  // dropDownStyle={"sm:w-[150px]"}
                  defaultInputValue={values.location}
                  placeholder="Select lga"
                  handleChange={(selectionOption) =>
                    setFieldValue("location", selectionOption.label)
                  }
                />
              </div>
              <div className="flex justify-between w-full gap-2 lg:gap-8">
                <SearchableDropdown2
                  label={"Owner's Gender"}
                  options={[
                    { label: "Male", value: "Male" },
                    { label: "Female", value: "Female" },
                    { label: "Mixed", value: "Mixed" },
                  ]}
                  name="isCurrentIncomeHigher"
                  error={errors.isCurrentIncomeHigher}
                  placeholder="Select answer"
                  fieldRequired={true}
                  defaultInputValue={values.gender}
                  handleChange={(selectionOption) =>
                    setFieldValue("gender", selectionOption.label)
                  }
                />
                <div className="flex flex-col">
                  <SearchableDropdown2
                    label={"Owner's Age Range"}
                    options={[
                      {
                        label: "15-29",
                        value: "15-29",
                      },
                      {
                        label: "30+",
                        value: "30+",
                      },
                      {
                        label: "Mixed",
                        value: "Mixed",
                      },
                    ]}
                    name="state"
                    error={errors.ageRange}
                    fieldRequired={true}
                    placeholder="Select state"
                    defaultInputValue={values.ageRange}
                    handleChange={(selectionOption) => {
                      setFieldValue("ageRange", selectionOption?.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-between gap-2">
                <InputText
                  label={"Latitude"}
                  name="latitude"
                  fieldRequired={true}
                  value={values.latitude}
                  // className={"sm:w-[150px]"}
                  error={errors.latitude}
                  placeholder={"Enter Latitude"}
                  handleChange={handleChange}
                />
                <InputText
                  label={"Longtitude"}
                  name="longitude"
                  value={values.longitude}
                  // className={"sm:w-[150px]"}
                  fieldRequired={true}
                  error={errors.longitude}
                  placeholder={"Enter Longtitude"}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex justify-between gap-2">
                <InputText
                  label={"Contact Person First Name"}
                  name="contactPersonFirstName"
                  value={values.contactPersonFirstName}
                  fieldRequired={true}
                  // className={"sm:w-[150px]"}
                  error={errors.contactPersonFirstName}
                  placeholder={"Enter contact person first name"}
                  handleChange={handleChange}
                />
                <InputText
                  label={"Contact Person Last Name"}
                  name="contactPersonLastName"
                  value={values.contactPersonLastName}
                  fieldRequired={true}
                  // className={"sm:w-[150px]"}
                  error={errors.contactPersonLastName}
                  placeholder={"Enter contact person last name"}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex justify-between gap-2">
                <InputText
                  label={"Phone Number"}
                  name="phoneNumber"
                  value={values.phoneNumber}
                  fieldRequired={true}
                  // className={"sm:w-[150px]"}
                  error={errors.phoneNumber}
                  placeholder={"Enter phone number"}
                  handleChange={handleChange}
                />

                <InputText
                  label={"Email Address"}
                  name="email"
                  value={values.email}
                  fieldRequired={true}
                  // className={"sm:w-[150px]"}
                  error={errors.email}
                  placeholder={"Enter email address"}
                  handleChange={handleChange}
                />
              </div>

              <div className="flex justify-between gap-2">
                <InputText
                  label={"Year of Incorporation"}
                  name="yearOfIncorporation"
                  fieldRequired={true}
                  // className={"sm:w-[150px]"}
                  value={values.yearOfIncorporation}
                  error={errors.yearOfIncorporation}
                  placeholder={"Enter year of incorporation"}
                  handleChange={handleChange}
                />
                <InputText
                  label={"RC Number"}
                  name="rcNumber"
                  value={values.rcNumber}
                  // fieldRequired={true}
                  // className={"sm:w-[150px]"}
                  error={errors.rcNumber}
                  placeholder={"Enter rc number"}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex justify-between gap-2">
                <InputText
                  label={"Number of Male Employees"}
                  name="noOfMaleEmployee"
                  value={values.noOfMaleEmployee}
                  // fieldRequired={true}
                  // className={"sm:w-[150px]"}
                  error={errors.noOfMaleEmployee}
                  placeholder={"Enter number of male employees"}
                  handleChange={handleChange}
                />
                <InputText
                  label={"Number of Female Employees"}
                  name="noOfFemaleEmployee"
                  value={values.noOfFemaleEmployee}
                  // fieldRequired={true}
                  // className={"sm:w-[150px]"}
                  error={errors.noOfFemaleEmployee}
                  placeholder={"Enter number of female employees"}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex justify-between gap-2">
                <InputText
                  label={"Avg Monthly Collection Cap(Kg)"}
                  name="avgMonthlyCollectionCap"
                  value={values.avgMonthlyCollectionCap}
                  // className={"sm:w-[150px]"}
                  error={errors.avgMonthlyCollectionCap}
                  placeholder={"Enter avg monthly collection cap"}
                  handleChange={handleChange}
                />
                <InputText
                  label={"Avg Monthly Procession Cap(Kg)"}
                  name="avgMonthlyProcessionCap"
                  value={values.avgMonthlyProcessionCap}
                  // className={"sm:w-[150px]"}
                  error={errors.avgMonthlyProcessionCap}
                  placeholder={"Enter avg monthly procession cap"}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex justify-between gap-2">
                <InputText
                  label={"Cooperative Name, if applicable"}
                  name="cooperative"
                  value={values.cooperative}
                  // className={"sm:w-[150px]"}
                  error={errors.cooperative}
                  placeholder={"Enter cooperative"}
                  handleChange={handleChange}
                />
                <div className="flex flex-col">
                  <DatePicker
                    selected={
                      values.enrollmentDate ? values.enrollmentDate : startDate
                    }
                    onChange={(date) => {
                      setStartDate(date);
                      setFieldValue(
                        "enrollmentDate",
                        moment(date).format("YYYY-MM-DD")
                      );
                    }}
                    customInput={<ExampleCustomInput />}
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                  />
                  {/* {errors.dateOfBirth ? (
                      <p className="text-red-500">{errors.dateOfBirth}</p>
                    ) : (
                      ""
                    )} */}
                </div>
              </div>

              <div className="flex gap-2 justify-center">
                <button
                  className="mx-auto px-2 w-full h-[40px] font-normal text-xs flex justify-center items-center gap-3 disabled:cursor-not-allowed border border-pfBlack md:w-272"
                  onClick={() => closeModal()}
                >
                  Cancel
                </button>

                {requestType === "edit" ? (
                  <button
                    type="submit"
                    disabled={loading ? true : false}
                    onClick={handleSubmit}
                    className="bg-green-700 text-white flex justify-center items-center h-[40px] w-full gap-2"
                  >
                    {loading ? "Updating..." : "Save changes"}
                  </button>
                ) : (
                  <button
                    type="submit"
                    disabled={loading ? true : false}
                    onClick={handleSubmit}
                    className="bg-green-700 text-white flex justify-center items-center h-[40px] w-full gap-2"
                  >
                    {loading ? "Creating..." : "Create Aggregator"}
                  </button>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
